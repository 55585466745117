$font-primary: "Lato Regular";
$font-secondary: "Lato Bold";

$white: #fff;
$black: #000000;

$primary: #81cfff;
$secondary: #e16262;
$darken: #21243d;
$grayish: rgba(0, 0, 0, 0.25);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxxl: 1700px,
  xxxxl: 1900px
);
